export const initialCompanyDetails = {
    "companyId": 0,
    "industryId": 1,
    "sectorId":1,
    "companyTypeId": 1,
    "name": "",
    "isActive": true,
    "softDelete": false,
    "createdBy": 0,
    "createdDate": "2024-04-25T07:12:40.408Z",
    "updatedBy": 0,
    "updatedDate": "2024-04-25T07:12:40.408Z",
    "companyStatusId": 0,
    "companyName": "",
    "companyHQAddressId": 0,
    "ceoName": "",
    "ceoEmail": "",
    "ceoPhone": "",
    "cfoName": "",
    "cfoEmail": "",
    "cfoPhone": "",
    "otherContactTitle": "",
    "otherContactPhone": "",
    "otherContactName": "",
    "otherContactEmail": "",
    "legalCounselFirmName": "",
    "legalCounselName": "",
    "legalCounselMainAddressId": 0,
    "legalCounselEmail": "",
    "legalCounselContactPhone": "",
    "boardRepresentation": "",
    "boardMembers": "",
    "accountantFirmName": "",
    "accountantName": "",
    "accountantMainAddressId": 0,
    "accountantEmail": "",
    "accountantContactPhone": "",
    "dueDiligenceLeader": "",
    "dueDiligenceTeamMembers": "",
    "investmentClosingDate": "",
    "investmentCommitment": 0,
    "initialInvestmentAmount": 0,
    "investedCapitalToDate": 0,
    "investmentMultiple": 0,
    "distributionsFromCompany": 0,
    "investmentThesis": "",
    "investmentStageId": 0,
    "useOfFunds": "",
    "investmentTerms": "",
    "investorGroupOwnership": 0,
    "investorGroupMembers": "",
    "valuationAtClosing": 0,
    "valuationMultiple": 0,
    "valuationDate": "",
    "currentValuation": 0,
    "managementOwnerShipPercentage": 0,
    "ownershipPercentage": 0,
    "ownershipStructure": "",
    "reportedValue": 0,
    "unitsParValue": 0,
    "exitStatusId": 0,
    "exitDate": "",
    "realizedProceeds": 0,
    "numberOfEmployees": 0,
    "eboolDAMargin": 0,
    "netIncomeMargin": 0,
    "revenueGrowthRate": 0,
    "yearOverYearGrowthPercentage": 0,
    "grossIRR": 0,
    "grossMOIC": 0,
    "ltmEboolda": 0,
    "netIRR": 0,
    "netMOIC": 0,
    "returnOnAssets": 0,
    "returnOnEquity": 0,
    "tev": 0,
    "tevMultiple": 0,
    "latestFinancialStatementsDate": "",
    "latestFinancialStatements": "",
    "latestOperatingUpdatesDate": "",
    "fundManagerNotes": "",
    "companyDataRoom": "",
    "dueDiligenceRequiredDocuments": "",
    "portofolioCompanyManagerID": 0,
    "currentOnboardingStep": 0,
    "pcManager": {},
    "industryName": "",
    "sectorName": "",
    "companyTypeName": "",
    "companyStatusName": "",
    "companyHQAddress1": "",
    "companyHQAddress2": "",
    "companyHQAddress3": "",
    "companyHQState": 0,
    "companyHQCity": 0,
    "companyHQCountry": 0,
    "companyHQCountryName": "",
    "companyHQZipCode": "",
    "legalCounselAddress1": "",
    "legalCounselAddress2": "",
    "legalCounselAddress3": "",
    "legalCounselState": 0,
    "legalCounselCity": 0,
    "legalCounselCountry": 0,
    "legalCounselZipCode": "",
    "accountantFirmAddress1": "",
    "accountantFirmAddress2": "",
    "accountantFirmAddress3": "",
    "accountantFirmState": 0,
    "accountantFirmCity": 0,
    "accountantFirmCountry": 0,
    "accountantFirmZipCode": "",
    "investmentStageName": ""
  };

export const investDetails = {
    "portfolioCompanyTransactionId": 0,
    "transactionTypeID": 0,
    "transactionTypeName": "",
    "transactionDate": new Date(),
    "shareTypeID": "",
    "shareTypeName": "",
    "quantity": "",
    "sharePrice": "",
    "transactionCost": 0,
    "amount": "",
    "roundOfInvestmentID": 0,
    "roundOfInvestmentName": "",
    "particulars": "",
    "narration": "",
    "companyID": 0,
    "createdBy": 0,
    "createdDate": "2024-04-25T11:45:37.034Z",
    "updatedBy": 0,
    "updatedDate": "2024-04-25T11:45:37.034Z",
    "openingAndClosingBalance": {
      "openingBalance": 0,
      "openingBalanceDate": "string",
      "closingBalance": 0,
      "closingBalanceDate": "string"
    }
  }

export const sellDetails = {
    "portfolioCompanyTransactionId": 0,
    "transactionTypeID": 0,
    "transactionTypeName": "",
    "transactionDate": new Date(),
    "shareTypeID": "",
    "shareTypeName": "",
    "quantity": "",
    "sharePrice": "",
    "transactionCost": 0,
    "amount": "",
    "roundOfInvestmentID": 0,
    "roundOfInvestmentName": "",
    "particulars": "",
    "narration": "",
    "companyID": 0,
    "createdBy": 0,
    "createdDate": "2024-04-25T11:45:37.034Z",
    "updatedBy": 0,
    "updatedDate": "2024-04-25T11:45:37.034Z",
    "openingAndClosingBalance": {
      "openingBalance": 0,
      "openingBalanceDate": "string",
      "closingBalance": 0,
      "closingBalanceDate": "string"
    }
}

export const otherInvestmentDetails = {
    "portfolioCompanyTransactionId": 0,
    "transactionTypeID": 0,
    "transactionTypeName": "",
    "transactionDate": new Date(),
    "shareTypeID": 0,
    "shareTypeName": "",
    "quantity": 0,
    "sharePrice": 0,
    "transactionCost": 0,
    "amount": "",
    "roundOfInvestmentID": 0,
    "roundOfInvestmentName": "",
    "particulars": "",
    "narration": "",
    "companyID": 0,
    "createdBy": 0,
    "createdDate": "2024-04-25T11:45:37.034Z",
    "updatedBy": 0,
    "updatedDate": "2024-04-25T11:45:37.034Z",
    "openingAndClosingBalance": {
      "openingBalance": 0,
      "openingBalanceDate": "string",
      "closingBalance": 0,
      "closingBalanceDate": "string"
    }
}

export const periodDropDownEnum = {
    YEAR: 'year',
    MONTH: 'month',
    CUSTOM_DATE: 'customdate'
}

export const periodDropDownDetails = [
    {
        value: 'currentYear',
        label: 'Current Year'
    },
    {
        value: 'previousYear',
        label: 'Previous Year'
    },
    {
        value: periodDropDownEnum.YEAR,
        label: 'Select Year'
    },
    {
        value: periodDropDownEnum.MONTH,
        label: 'Select Month'
    },
    {
        value: periodDropDownEnum.CUSTOM_DATE,
        label: 'Custom'
    },
    {
        value: 'all',
        label: 'All to Date'
    },
]

export const transactionMonths = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
];

export const portfolioPerformanceChartTypes = {
    REVENUE_EBITDA: "Revenue & EBITDA Growth",
    MARGINS: "Margins over the quarters",
    FREE_CASH_FLOW: "Free cash flow trend",
    VALUATION_GRAPH: "Valuation graph",
    IRR: "IRR",
    MOIC: "MOIC"
}

export const portfolioPerformanceCharts = [
    { label: 'Revenue & EBITDA Growth', value: portfolioPerformanceChartTypes.REVENUE_EBITDA },
    { label: 'Margins over the quarters', value: portfolioPerformanceChartTypes.MARGINS },
    { label: 'Free cash flow trend', value: portfolioPerformanceChartTypes.FREE_CASH_FLOW },
    { label: 'Valuation graph', value: portfolioPerformanceChartTypes.VALUATION_GRAPH },
    { label: 'IRR', value: portfolioPerformanceChartTypes.IRR },
    { label: 'MOIC', value: portfolioPerformanceChartTypes.MOIC }
]

